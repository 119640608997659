import recallServices from '../../../services/notisphere/recall'
import RecallDetails from './RecallDetails'
import ChangeHistory from './ChangeHistory'

export default {
    props: {
        id: {type: String, default: null}
    },
    components: {
        RecallDetails,
        ChangeHistory
    },
    data() {
        return {
            item: null,
            tabDetails: true,
            tabHistory: false
        }
    },
    created() {
        this.getRecallById()
    },
    methods: {
        getRecallById() {
            recallServices.getRecallById(this.id).then(resp => {
                if (resp.error){
                    this.$router.push({ name: 'noti_Recall' })
                } else {
                    this.item = resp.data.d
                }
            })
        },
        deleteRecall(){
            this._showConfirm('Are you sure you want to delete the recall, this cannot be undone?', {title: 'Delete Recall', variant: 'danger'}).then(confirm => {
                if (confirm) {
                    recallServices.deleteRecall(this.id, this.item.supplier_id).then(resp => {
                        if (!resp.error) {
                            this.$router.push({ name: 'noti_Recall' })
                            this._showToast('Recall Deleted!')
                        }
                    })
                }
            })
        }
    }
}