import { RECALL_CLASSIFICATIONS, NOTI_RECALL_STATUS,OUTPUT_DATE_FORMAT } from '../../../../misc/commons'
import DocumentViewModel from '../../../../components/part/shared/DocumentViewModel'

export default {
    props: {
        item: {type: Object, default: null}
    },
    components: {
        DocumentViewModel
    },
    data() {
        return {
            recall_classifications: RECALL_CLASSIFICATIONS,
            recall_status: NOTI_RECALL_STATUS,
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT
        }
    },
    methods: {
        ChangeIdentifires(fields, field, event) {
            let rownumber = field.options.filter(x => x.value == event)[0].rowNumber
            let uniquefields = fields.filter(x => x.is_unique_identifier == true && x.field_name != field.field_name)
            for (let index = 0; index < uniquefields.length; index++) {
                let uniquefield = fields.filter(x => x.is_unique_identifier == true && x.field_name == uniquefields[index].field_name)[0]
                uniquefield.selected_value = uniquefield.options.filter(x => x.rowNumber == rownumber)[0].value
            }
        }
    }

}