import recallServices from '../../../../services/notisphere/recall'

export default {
    props: {
        communicationId: {type: String, default: null}
    },
    data() {
        return {
            histories: []
        }
    },
    created(){
        this.GetRecallHistories()
    },
    methods: {
        GetRecallHistories () {
            recallServices.GetRecallHistories(this.communicationId).then(resp => {
                if (!resp.error){
                    this.histories = resp.data.d
                }
            })
        }
    }
}